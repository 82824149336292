import FilmCourse from "../components/FilmCourse/FilmCourse"
import MakeupCourse from "../components/sillabus/Sillabus"
import styles from "./ForthScreen.module.css"

const ForthScreen=()=>{


return <>
   {/* <div className={styles.title}>שירותים נוספים</div> */}
   {/* <FilmCourse/> */}

</>


}
export default ForthScreen